<span class="op-baseline-legends--filter">
  <span [textContent]="legendDescription"></span>
  &ngsp;
  <spot-tooltip
    *ngIf="userOffset !== offset"
  >
        <span
          slot="trigger"
          class="spot-icon spot-icon_info2"
        ></span>
        <p
          slot="body"
          class="spot-body-small op-baseline-legends--tooltip"
        >
          {{ text.in_your_timezone }}
          {{ localDate }}
        </p>
  </spot-tooltip>
</span>
<div class="op-baseline-legends--details hidden-for-mobile">
  <span class="spot-icon spot-icon_add op-baseline-legends--details-icon-added"></span>
  <span class="op-baseline-legends--details-added">{{text.now_meets_filter_criteria}}</span>
  <span class="spot-icon spot-icon_minus1 op-baseline-legends--details-icon-removed"></span>
  <span class="op-baseline-legends--details-removed">{{text.no_longer_meets_filter_criteria}}</span>
  <span class="spot-icon spot-icon_arrow-left-right op-baseline-legends--details-icon-changed"></span>
  <span class="op-baseline-legends--details-changed">{{text.maintained_with_changes}}</span>
</div>
