<a
  class="op-ian-bell op-app-menu--item-action"
  data-qa-selector="op-ian-bell"
  [href]="notificationsPath()"
>
  <span class="spot-icon spot-icon_bell"></span>
  <ng-container *ngIf="(unreadCount$ | async) as unreadCount">
    <span
      *ngIf="unreadCount !== 0"
      class="op-ian-bell--indicator"
      data-qa-selector="op-ian-notifications-count"
      [textContent]="unreadCountText$ | async"
    ></span>
  </ng-container>
</a>
