<button
  *ngIf="this.currentProject.id !== null || this.deviceService.isMobile"
  class="op-app-menu--item-action"
  id="main-menu-toggle"
  aria-haspopup="true"
  type="button"
  [attr.title]="toggleTitle"
  (click)="toggle($event)"
>
  <span class="spot-icon spot-icon_hamburger"></span>
  <span class="spot-icon spot-icon_close main-menu_toggle-close"></span>
</button>
